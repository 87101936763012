const PATH = {
  HOME: "/",
  INNER: {
    HOME: "home",
    ABOUT: "about",
    WORK: "work",
    CONTACT: "contact",
  },
};

export default PATH;
